const handleNullField = ({ data, isAdd, fileFieldList }) => {
  const newData = { ...data };
  Object?.keys?.(newData)?.forEach(key => {
    const isNullField =
      newData?.[key] === undefined ||
      newData?.[key] === null ||
      newData?.[key] === '';

    /** Add Modal */
    if (isAdd && isNullField) {
      delete newData?.[key];
    }

    /** Edit Modal */
    if (!isAdd && isNullField) {
      if (fileFieldList?.includes?.(key) && newData?.[key] === null) {
        delete newData?.[key];
      } else {
        newData[key] = '';
      }
    }

    /** Delete Used Field */
    if (key?.endsWith('_file_name')) {
      delete newData[key];
    }
  });

  return newData;
};

export default handleNullField;
