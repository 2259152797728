import dayjs from 'dayjs';
import combineNumber from '../../Common/Generator/combineNumber';
import { concatPhoneNumber } from '../../../Common/Handler/phoneNumberHandler';
import {
  DATE_FIELD_LIST,
  FILE_FIELD_LIST,
  PHONE_NUMBER_FIELD_LIST,
  SSN_FIELD_LIST,
} from '../../../../constants/Master/Driver/preprocessingFields';
import handleNullField from '../../Common/Handler/handleNullField';

const handleDriverData = ({ data, isAdd }) => {
  data.ssn = combineNumber(SSN_FIELD_LIST, data);

  PHONE_NUMBER_FIELD_LIST.forEach(field => {
    const phoneNumber = combineNumber(
      [`${field}_first`, `${field}_second`, `${field}_third`],
      data,
    );

    data[field] = concatPhoneNumber({
      country: 'US',
      phone: phoneNumber,
    });
  });

  DATE_FIELD_LIST.forEach(field => {
    if (data?.[field])
      data[field] = dayjs(data[field])?.isValid()
        ? dayjs(data[field]).format('YYYY-MM-DD')
        : null;
  });

  data.per_hour = data?.per_hour ? data.per_hour : false;

  if (!data?.per_hour) {
    delete data?.rate;
  }

  if (data.created_by) {
    data.created_by = data.created_by.split(',')[0];
  }
  if (data.updated_by) {
    data.updated_by = data.updated_by.split(',')[0];
  }

  data.geofence = {
    place_id: data.place_id,
    latitude: data.lat,
    longitude: data.lng,
  };

  const newData = handleNullField({
    data,
    isAdd,
    fileFieldList: FILE_FIELD_LIST,
  });

  return newData;
};

export default handleDriverData;
