import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { REGEX_NUMBER_REGEX } from '../../../../../constants/Common/regex';
import sliceNumber from '../../../../../utils/Master/Common/Generator/sliceNumber';
import {
  handleCheckChange,
  handleDayChange,
  handleInputChange,
} from '../../../../../utils/Master/Common/Handler/changeInputHandler';
import CommonCheckbox from '../../../../Inputs/CommonCheckbox/CommonCheckbox';
import CommonDate from '../../../../Inputs/CommonDate/CommonDate';
import CommonFile from '../../../../Inputs/CommonFile/CommonFile';
import CommonMultipleInput from '../../../../Inputs/CommonMultipleInput/CommonMultipleInput';
import CommonTextInput from '../../../../Inputs/CommonTextInput/CommonTextInput';

const ExtraInfo = ({
  isEditable,
  data,
  inputController,
  editMode,
  isCompany,
  stateObject,
  ...props
}) => {
  const { getValues } = useFormContext();
  const einNo = getValues('ein_no');
  const selectedDiv = useWatch({ name: 'div' });
  const sliceSSN = sliceNumber(data?.ssn, [3, 2, 4]);

  const [isRequiredSSN, setIsRequiredSSN] = useState(false);
  useEffect(() => {
    setIsRequiredSSN(!einNo);
  }, [einNo]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="w-[213px]">
          <CommonTextInput
            maxLength={20}
            label="DL #"
            defaultValue={data?.dl_no}
            required={isCompany}
            inputName="dl_no"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[213px]">
          <CommonDate
            label="EXP"
            defaultValue={data?.dl_exp}
            required={isCompany}
            inputName="dl_exp"
            onChangeHandler={handleDayChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[214px] flex justify-between">
          <div className="w-[50px]">
            <CommonCheckbox
              label="HAZ"
              borderVisible
              onChangeHandler={handleCheckChange}
              options={[
                {
                  inputName: 'hazmat',
                  defaultValue: editMode ? data?.hazmat : false,
                },
              ]}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[154px]">
            <CommonFile
              inputName="dl_file"
              defaultValue={
                data?.dl_file
                  ? [data?.dl_file?.split('/').pop(), null]
                  : ['', null]
              }
              fileUrl={data?.dl_file}
              required={isCompany}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      {!isCompany && (
        <>
          <div className="flex justify-between">
            <div className="w-[213px]">
              <CommonTextInput
                maxLength={9}
                label="EIN #"
                defaultValue={data?.ein_no?.trim()}
                inputName="ein_no"
                validate={{
                  default: {
                    minLength: {
                      value: 9,
                      message: 'Must be 9 characters.',
                    },
                  },
                }}
                onChangeHandler={handleInputChange}
                regex={REGEX_NUMBER_REGEX}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[213px]">
              <CommonTextInput
                maxLength={10}
                label="MC #"
                defaultValue={data?.mc_no}
                inputName="mc_no"
                onChangeHandler={handleInputChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[214px]">
              <CommonTextInput
                maxLength={10}
                label="DOT #"
                defaultValue={data?.dot_no}
                inputName="dot_no"
                onChangeHandler={handleInputChange}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex gap-x-[20px]">
            <div className="w-[213px]">
              <CommonMultipleInput
                label="SSN"
                defaultValue={sliceSSN}
                required={() => {
                  return [isRequiredSSN, isRequiredSSN, isRequiredSSN];
                }}
                maxLength={[3, 2, 4]}
                inputName={['ssn_first', 'ssn_second', 'ssn_third']}
                validate={[
                  {
                    default: {
                      required: {
                        value: isRequiredSSN,
                        message: 'First field is mandatory.',
                      },
                      minLength: {
                        value: 3,
                        message: 'Please insert 3 numbers at first field.',
                      },
                    },
                  },
                  {
                    default: {
                      required: {
                        value: isRequiredSSN,
                        message: 'Second field is mandatory.',
                      },
                      minLength: {
                        value: 2,
                        message: 'Please insert 2 numbers at second field.',
                      },
                    },
                  },
                  {
                    default: {
                      required: {
                        value: isRequiredSSN,
                        message: 'Third field is mandatory.',
                      },
                      minLength: {
                        value: 4,
                        message: 'Please insert 4 numbers at third field.',
                      },
                    },
                  },
                ]}
                regex={REGEX_NUMBER_REGEX}
                onChangeHandler={[
                  handleInputChange,
                  handleInputChange,
                  handleInputChange,
                ]}
                disabled={[!isEditable, !isEditable, !isEditable]}
              />
            </div>
            <div className="w-[213px]">
              <CommonFile
                inputName="ssn_file"
                defaultValue={
                  data?.ssn_file
                    ? [data?.ssn_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.ssn_file}
                required={isRequiredSSN}
                disabled={!isEditable}
              />
            </div>
          </div>
        </>
      )}
      <div className="flex justify-between">
        <div className="w-[213px]">
          <CommonTextInput
            maxLength={20}
            label="MEDICAL #"
            defaultValue={data?.medical_no}
            required={isCompany}
            inputName="medical_no"
            onChangeHandler={handleInputChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[213px]">
          <CommonDate
            label="EXP"
            defaultValue={data?.medical_exp}
            required={isCompany}
            inputName="medical_exp"
            onChangeHandler={handleDayChange}
            disabled={!isEditable}
          />
        </div>
        <div className="w-[214px]">
          <CommonFile
            inputName="medical_file"
            defaultValue={
              data?.medical_file
                ? [data?.medical_file?.split('/').pop(), null]
                : ['', null]
            }
            fileUrl={data?.medical_file}
            required={isCompany}
            disabled={!isEditable}
          />
        </div>
      </div>
      <div className="flex gap-x-[10px]">
        {isCompany && (
          <>
            <div className="w-[165px]">
              <CommonMultipleInput
                label="SSN"
                required={[true, true, true]}
                defaultValue={sliceSSN}
                maxLength={[3, 2, 4]}
                inputName={['ssn_first', 'ssn_second', 'ssn_third']}
                validate={[
                  {
                    default: {
                      required: {
                        value: true,
                        message: 'First field is mandatory.',
                      },
                      minLength: {
                        value: 3,
                        message: 'Please insert 3 numbers at first field.',
                      },
                    },
                  },
                  {
                    default: {
                      required: {
                        value: true,
                        message: 'Second field is mandatory.',
                      },
                      minLength: {
                        value: 2,
                        message: 'Please insert 2 numbers at second field.',
                      },
                    },
                  },
                  {
                    default: {
                      required: {
                        value: true,
                        message: 'Third field is mandatory.',
                      },
                      minLength: {
                        value: 4,
                        message: 'Please insert 4 numbers at third field.',
                      },
                    },
                  },
                ]}
                regex={REGEX_NUMBER_REGEX}
                onChangeHandler={[
                  handleInputChange,
                  handleInputChange,
                  handleInputChange,
                ]}
                disabled={[!isEditable, !isEditable, !isEditable]}
              />
            </div>
            <div className="w-[155px] mr-[10px]">
              <CommonFile
                inputName="ssn_file"
                defaultValue={
                  data?.ssn_file
                    ? [data?.ssn_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.ssn_file}
                required
                disabled={!isEditable}
              />
            </div>
          </>
        )}
        <div className={`${!isCompany ? 'w-[223px]' : 'w-[165px]'}`}>
          <CommonDate
            label="TWIC EXP"
            defaultValue={data?.twic_exp}
            inputName="twic_exp"
            onChangeHandler={handleDayChange}
            disabled={!isEditable}
          />
        </div>
        <div className={`${!isCompany ? 'w-[213px]' : 'w-[155px]'}`}>
          <CommonFile
            inputName="twic_file"
            defaultValue={
              data?.twic_file
                ? [data?.twic_file?.split('/').pop(), null]
                : ['', null]
            }
            fileUrl={data?.twic_file}
            disabled={!isEditable}
          />
        </div>
      </div>
      {!isCompany ? (
        <div className="flex justify-between">
          <div className="w-[155px]">
            <CommonTextInput
              label="INSURANCE #"
              maxLength={20}
              defaultValue={data?.insurance_no}
              inputName="insurance_no"
              onChangeHandler={handleInputChange}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[155px]">
            <CommonDate
              label="EXP"
              defaultValue={data?.insurance_exp}
              inputName="insurance_exp"
              onChangeHandler={handleDayChange}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[155px]">
            <CommonTextInput
              maxLength={100}
              label="INSURANCE COMPANY"
              defaultValue={data?.insurance_company}
              inputName="insurance_company"
              onChangeHandler={handleInputChange}
              disabled={!isEditable}
            />
          </div>
          <div className="w-[155px]">
            <CommonFile
              inputName="insurance_file"
              defaultValue={
                data?.insurance_file
                  ? [data?.insurance_file?.split('/')?.pop(), null]
                  : ['', null]
              }
              fileUrl={data?.insurance_file}
              disabled={!isEditable}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="flex gap-x-[10px]">
            <div className="w-[165px]">
              <CommonDate
                label="MVR"
                defaultValue={data?.mvr}
                required
                inputName="mvr"
                onChangeHandler={handleDayChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[155px] mr-[10px]">
              <CommonFile
                inputName="mvr_file"
                defaultValue={
                  data?.mvr_file
                    ? [data?.mvr_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.mvr_file}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="w-[330px]">
              <CommonFile
                label="APPLICATION"
                inputName="application"
                defaultValue={
                  data?.application
                    ? [data?.application?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.application}
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex gap-x-[10px]">
            <div className="w-[165px]">
              <CommonDate
                label="ROAD TEST"
                defaultValue={data?.road_test}
                required
                inputName="road_test"
                onChangeHandler={handleDayChange}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[155px] mr-[10px]">
              <CommonFile
                inputName="road_test_file"
                defaultValue={
                  data?.road_test_file
                    ? [data?.road_test_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.road_test_file}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="w-[330px]">
              <CommonFile
                label="10 YRS PRE EMPLOYMENT"
                inputName="yrs_10"
                defaultValue={
                  data?.yrs_10
                    ? [data?.yrs_10?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.yrs_10}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex gap-x-[10px]">
            <div className="w-[165px]">
              <CommonDate
                label="DRUG TEST"
                defaultValue={data?.drug_test}
                required
                inputName="drug_test"
                onChangeHandler={handleDayChange}
                width="w-[165px]"
                disabled={!isEditable}
              />
            </div>
            <div className="w-[155px] mr-[10px]">
              <CommonFile
                inputName="drug_test_file"
                defaultValue={
                  data?.drug_test_file
                    ? [data?.drug_test_file?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.drug_test_file}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="w-[330px]">
              <CommonFile
                label="OTHER"
                inputName="other"
                defaultValue={
                  data?.other
                    ? [data?.other?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.other}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[155px]">
              <CommonFile
                label="TAX FORM"
                inputName="tax_form"
                defaultValue={
                  data?.tax_form
                    ? [data?.tax_form?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.tax_form}
                required={stateObject?.[selectedDiv] !== 'TX'}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[155px]">
              <CommonFile
                label="W-4"
                inputName="w4"
                defaultValue={
                  data?.w4 ? [data?.w4?.split('/').pop(), null] : ['', null]
                }
                fileUrl={data?.w4}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="w-[155px]">
              <CommonFile
                label="I-9"
                inputName="i9"
                defaultValue={
                  data?.i9 ? [data?.i9?.split('/').pop(), null] : ['', null]
                }
                fileUrl={data?.i9}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="w-[155px]">
              <CommonFile
                label="ANNUAL VIOLATION"
                inputName="annual_violation"
                defaultValue={
                  data?.annual_violation
                    ? [data?.annual_violation?.split('/').pop(), null]
                    : ['', null]
                }
                fileUrl={data?.annual_violation}
                disabled={!isEditable}
              />
            </div>
          </div>
        </>
      )}
      {!isCompany && (
        <div className="flex justify-between">
          <div className="w-[330px]">
            <CommonFile
              label="APPLICATION"
              inputName="application"
              defaultValue={
                data?.application
                  ? [data?.application?.split('/').pop(), null]
                  : ['', null]
              }
              fileUrl={data?.application}
              required
              disabled={!isEditable}
            />
          </div>
          <div className="w-[330px]">
            <CommonFile
              label="W-9"
              inputName="w9"
              required
              defaultValue={
                data?.w9 ? [data?.w9?.split('/').pop(), null] : ['', null]
              }
              fileUrl={data?.w9}
              disabled={!isEditable}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraInfo;
