import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import CommonLoading from '../../../CommonLoading/CommonLoading';
import CommonButton from '../../../CommonButton/CommonButton';
import DriverInfo from './DriverInfo';
import DriverTruck from './DriverTruck';
import DriverBank from './DriverBank';
import ExtraInfo from './ExtraInfo';
import usePostDriverData from '../../../../hooks/Master/Driver/usePostDriverData';
import useUpdateDriverData from '../../../../hooks/Master/Driver/useUpdateDriverData';
import handleReset from '../../../../utils/Master/Common/Handler/handleReset';
import updateButtonDisabled from '../../../../utils/Common/Handler/updateButtonDisabled';

const SideModalContent = ({
  isCompany,
  setIsCompany,
  data,
  isEditable,
  editMode,
  gridRef,
  ...props
}) => {
  const userID = localStorage.getItem('userId');

  const inputController = useForm({
    shouldUnregister: true,
  });

  const buttonRef = useRef(null);

  const { isPending: isPostPending, mutate: postDriverMutate } =
    usePostDriverData({
      editMode,
      handleReset,
      inputController,
      isAdd: true,
      ...props,
    });

  const { isPending: isUpdatePending, mutate: updateDriverMutate } =
    useUpdateDriverData({ gridRef, isAdd: false, ...props });

  const renderDivideLine = () => {
    return (
      <div className="w-full flex mb-[10px]">
        <div className="w-full h-px bg-[#878D9B] my-[16px]" />
      </div>
    );
  };

  const commonProps = {
    isCompany,
    data,
    inputController,
    isEditable,
    editMode,
    renderDivideLine,
  };

  const handleDriverSubmit = submitData => {
    if (buttonRef?.current)
      updateButtonDisabled({ ref: buttonRef, disabled: true });
    if (editMode) {
      updateDriverMutate({ submitData, buttonRef });
    } else {
      postDriverMutate({ submitData, buttonRef });
    }
  };

  return (
    <div>
      {(isPostPending || isUpdatePending) && (
        <CommonLoading open={isPostPending || isUpdatePending} />
      )}
      <div
        className="pb-5"
        onBlur={() => {
          const currentDate = dayjs().format('MM/DD/YY');
          const dateInfo = `${userID}, ${currentDate}`;
          if (!data) {
            inputController?.setValue('created_by', dateInfo);
          }
          inputController?.setValue('updated_by', dateInfo);
        }}
      >
        <FormProvider {...inputController}>
          <form onSubmit={inputController.handleSubmit(handleDriverSubmit)}>
            <DriverInfo
              setIsCompany={setIsCompany}
              divListOption={props?.divListOption}
              {...commonProps}
            />
            {!isCompany && <DriverTruck {...commonProps} />}
            <DriverBank {...commonProps} />
            <ExtraInfo stateObject={props?.stateObject} {...commonProps} />
            {isEditable && (
              <div className="flex mt-[30px] justify-center gap-x-[20px]">
                <CommonButton
                  type="button"
                  title="Clear"
                  category="clear"
                  onClick={() => handleReset(inputController)}
                >
                  Clear
                </CommonButton>
                <CommonButton
                  ref={buttonRef}
                  type="submit"
                  title="Save"
                  category="save"
                >
                  Save
                </CommonButton>
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default SideModalContent;
