export const FILE_FIELD_LIST = [
  'credit_app_file',
  'w9_file',
  'ssn_file',
  'bank_file',
  'void_check',
  'photo_file',
  'dl_file',
  'medical_file',
  'twic_file',
  'insurance_file',
  'mvr_file',
  'application',
  'road_test_file',
  'yrs_10',
  'drug_test_file',
  'other',
  'tax_form',
  'w4',
  'i9',
  'annual_violation',
  'w9',
];

export const SSN_FIELD_LIST = ['ssn_first', 'ssn_second', 'ssn_third'];

export const PHONE_NUMBER_FIELD_LIST = [
  'cell_no',
  'home_no',
  'emergency_phone_no',
];

export const DATE_FIELD_LIST = [
  'hire_date',
  'rehire_date',
  'contract_date',
  'terminate_date',
  'dob',
  'legal_exp',
  'dl_exp',
  'medical_exp',
  'twic_exp',
  'insurance_exp',
  'mvr',
  'road_test',
  'drug_test',
];
